import { css } from 'styled-components';

const Fonts = css`

  @font-face {
    font-family: 'Pixel';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Pixel Times.ttf');
  };

  @font-face {
    font-family: 'Pixel';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Pixel Times Bold.ttf');
  };

  @font-face {
    font-family: 'NothingRus';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/NothingRus.ttf');
  };
  
  @font-face {
    font-family: 'Nothing';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/NothingYouCouldDo.ttf');
  };

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Manrope-ExtraLight.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/Manrope-Light.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/Manrope-Regular.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src: url('/assets/fonts/Manrope-Medium.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/Manrope-SemiBold.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/Manrope-Bold.ttf');
  }

  @font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/Manrope-ExtraBold.ttf');
  }
`;

export default Fonts;
