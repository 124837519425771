import Styled from './styled'
import React from 'react';
import { useLocation, Link, matchPath } from "react-router-dom";
import { Breadcrumb } from "antd";
import scroll from 'shared/hooks/useScroll';

const BreadCrumb = () => {
  scroll();
  const location = useLocation()
  const breadCrumbView = () => {
    const {pathname} = location
    const pathnames = pathname.split('/').filter((item) => item);
    const capitalize = (s: any) => s.charAt(0).toUpperCase() + s.slice(1);
    const path = "/members/:category";
    return (
      <Styled.BreadWrapper>
        {pathnames.length > 0 ? (
          <Breadcrumb.Item>
            <Link to='/'>Home</Link>
          </Breadcrumb.Item>
        ) : (
          null
        )}
        {pathnames.filter((name) => {
          return !matchPath(path, name)
        }).map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
          const isLast = index === pathnames.length - 1;
          return isLast ? (
            <Breadcrumb.Item key={index + 1}>
              {capitalize(name)}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={index + 1}>
              <Link to={`${routeTo}`}>
                {capitalize(name)}
              </Link>
            </Breadcrumb.Item>
          )
        })}
      </Styled.BreadWrapper>
    )
  }

  return <>{breadCrumbView()}</>
}

export default BreadCrumb;
