import styled, {css} from 'styled-components'
import {Link} from "react-router-dom";

type HeaderActive = {
  active?: boolean
}

type HeaderLogo = {
  active?: boolean
}

type LinksType = {
  active?: boolean
}


const Header = styled.header<HeaderActive>`
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease;
  ${(props) => props.active && css`
    background-color: transparent;
  `}
`

const HeaderContent = styled.div`
  max-width: 1738px;
  margin: 0 auto;
  padding: 10px 15px;
  display: flex;
  align-items: center;
`

const Logo = styled.img<HeaderLogo>`
  max-width: 80px;
  max-height: 80px;
  transition: all 0.3s ease;
  ${(props) => props.active && css`
    max-width: 60px;
    max-height: 60px;
    opacity: 0;
  `}
`

const Links = styled.div<LinksType>`
  margin-left: auto;
  transition: all 0.3s ease;
  
  ${(props) => props.active && css `
    background: linear-gradient(135deg, #2C3E50 0%, #7F8C8D 100%);
    padding: 10px;
    border-radius: 10px;
    opacity: 0.7;
  `}
`

const NavLink = styled(Link)`
  margin-right: 15px;
  color: ${(props) => props.theme.Colors.white};
  font-size: 20px;
  transition: all 0.3s ease;
  display: inline-block;

  &:hover {
    color: white;
  }
  
  &:after {
    display: block;
    content: '';
    border-bottom: solid 1px ${(props) => props.theme.Colors.white};
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0 50%;
  }

  &:hover:after {
    transform-origin: 0 50%;
    transform: scaleX(1);
  }

  &:last-of-type {
    margin-right: 0;
  }
`;


export default {
  Header,
  HeaderContent,
  Logo,
  Links,
  NavLink,
}
