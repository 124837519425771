import React, { useEffect, useState } from 'react';
import Styled from './styled';
import { IoCloseCircle } from "react-icons/io5";

interface Props {
    title?: string; 
    src: string;
}

export const VideoCard = ({ title, src }: Props) => {
    const [fetchedTitle, setFTitle] = useState(title || '');
    const [fetchedAuthor, setFAuthor] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!title) {
            const fetchVideoData = async () => {
                const response = await fetch(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${src}`);
                const data = await response.json();
                try {
                    if (data && data.title) {
                        setFTitle(data.title);
                    } else {
                        throw new Error("Title not found");
                    }
                } catch (error) {
                    console.error("Error fetching video title:", error);
                }
                try {
                    if (data && data.author_name) {
                        setFAuthor(data.author_name);
                    } else {
                        throw new Error("Author name not found");
                    }
                } catch (error) {
                    console.error("Error fetching video author:", error);
                }
            };
            fetchVideoData();
        }
    }, [src, title]);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        document.body.style.overflow = "hidden";
    }
    const handleCloseModal = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "";
    }

    return (
        <>
            {isModalOpen && (
                <Styled.FadeOut onClick={handleCloseModal}>
                    <Styled.VideoModal>
                        <Styled.VideoModalHeader>
                            <Styled.VideoModalTitle>
                                {fetchedTitle || title}
                            </Styled.VideoModalTitle>
                            <Styled.VideoModalCloseButton onClick={handleCloseModal}>
                                <IoCloseCircle />
                            </Styled.VideoModalCloseButton>
                        </Styled.VideoModalHeader>
                        <Styled.VideoPlayerWrapper>
                            <Styled.VideoPlayerIframe
                            src={`https://www.youtube.com/embed/${src}`}
                            allowFullScreen
                            frameBorder={0} />
                        </Styled.VideoPlayerWrapper>
                    </Styled.VideoModal>
                </Styled.FadeOut>
            )}
            <Styled.VideoCardWrapper>
                <Styled.VideoCardImage src={`http://img.youtube.com/vi/${src}/0.jpg`} alt={fetchedTitle || title} draggable={false} />
                <Styled.VideoCardTitle>{fetchedTitle || title}</Styled.VideoCardTitle>
                {(fetchedAuthor !== '') && (
                    <Styled.VideoCardAuthor>Author: {fetchedAuthor}</Styled.VideoCardAuthor>
                )}
                <Styled.VideoCardButtonContainer>
                    <Styled.VideoCardButton onClick={handleOpenModal}>Посмотреть</Styled.VideoCardButton>
                </Styled.VideoCardButtonContainer>
            </Styled.VideoCardWrapper>
        </>
    );
};
