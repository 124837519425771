export type TMember = {
  id: number,
  nickname: string,
  rank?: string,
  place?: string,
  image?: string,
  age?: number,
  name?: string,
  about?: string,
  category?: string,
  server?: string,
  experience?: string,
  aboutGta?: string,
  retired?: boolean,
  executed?: boolean,
  youtube?: string,
  tg?: string,
  vk?: string,
  ds?: number,
}

export const supervisors: TMember[] = [
  {
    id: 1,
    category: 'Supervisors',
    nickname: 'FIOLETIV',
    rank: 'Lieutenant II',
    image: '/assets/images/members/FIOLETIV.png',
    server: 'Just, Original, Classic, Classic SF, LSRL, Original, SW Project, LSP, devGaming, LSRP, SFS, Gambit, GTA:W, GTA:W EN',
    age: 1999,
    name: 'Эльхан',
    youtube: 'https://youtube.com/FIOLETIV',
    experience: '12 лет',
    about: '',
    aboutGta: '',
    place: 'Азербайджан, Баку',
    ds: 193538026821910528,
    vk: 'https://vk.com/fioletiv',
  },
  {
    id: 2,
    category: 'Supervisors',
    nickname: 'Aldana',
    rank: 'Sergeant II',
    image: '/assets/images/members/Aldana.png',
    age: 2000,
    name: 'Александр',
    server: 'SW Project, LSP, LSRP, SFS, Gambit, GTA:W',
    experience: '8 лет',
    about: '',
    aboutGta: '',
    place: 'Беларусь, Брест',
    ds: 293466914494087169,
    vk: 'https://vk.com/znakhorenko',
  },
  {
    id: 3,
    category: 'Supervisors',
    nickname: 'DeymThis',
    rank: 'Police Officer 3+1',
    image: '/assets/images/members/DeymThis.png',
    age: 2000,
    name: 'Дмитрий',
    experience: '12 лет',
    server: 'Valakas RP, Original RP, Classic RP, LS:RL, LS:RP EN, LS:RP RU, LS:P, SW:P, devGaming, G:RP, GTA:W',
    about: 'Родился и вырос на грани между "традиционных скреп" и "пропагандой госдепа". Никогда не был в России. ' +
      'Учусь на инженера-строителя, так что через лет 10 принимаю заказики по застройке и восстановлению в ' +
      'Восточной Европе! \n' +
      'ИМХО: Без скилла, в РП играть без толку.\'',
    aboutGta: '10 лет без малого, начиная от локального ПД на одном из первых СВ, лидером был печально известный в ' +
      'элитных кругах сообщества РП отыгрывания сибирский банан. Далее пришел дядя Вайт вместе со всем LSPD.RU и' +
      ' принял всех, кто уже был в ПД на сервере. Это были солидные времена, потому что на сервере всегда была одна ' +
      'полицейская фракция. Далее СГТ в ПД Вайта, позже Ричерсона. Чуть позже начал играть в МЕТРО ' +
      'под предводительством Моргана, позже Фиолетива. Это вся история, остальное - это просто жалкие пародии.',
    place: 'Латвия, Даугавпилс',
    tg: 'https://t.me/Deymie',
    ds: 280802721546764298,
  },
]

export const juniors: TMember[] = [
  {
    id: 1,
    category: 'Officers',
    nickname: 'Daulet',
    rank: 'Police Officer III',
    image: '/assets/images/members/Daulet.png',
    age: 2003,
    name: 'Даулет',
    server: 'SW, Gambit, GTA W',
    experience: '7 лет',
    aboutGta: 'Прошел всю карьерную лестницу, с по1 до шефа полиции, в свате всегда по3. Два раза был на лидерских' +
      ' позициях, лспд и лвпд на гамбите.',
    vk: 'https://vk.com/sffdman',
    ds: 580023146321674243,
    about: 'Играю в гта индустрии еще с 2011 года, на серьезных проектах с года 15. Во фракции ЛСПД вступил еще в' +
      ' далеком 17 году, тогда еще было lspd.ru. В отделе сват с года 18. В будущем связываю жизнь с нефтяной' +
      ' промышленностью.',
    place: 'Республика Казахстан, Астана'
  },
  {
    id: 2,
    category: 'Officers',
    nickname: 'Legendary',
    rank: 'Police Officer III',
    image: '/assets/images/members/Legendary.png',
    age: 2003,
    name: 'Кирилл',
    server: 'Pacific, SW, LSP, LSL, Aware Reborn, GTAW, SA-ST, GRP, LS-Times',
    experience: '7 лет.',
    aboutGta: 'В 2015 начал путь с САДОКА, далее Пацифик в патрульном при Кукле.\n' +
      'После чего стал одним из костяка создании известного на Гамбите lssd.ru . С костяком я познакомился на сервере' +
      ' Southern District и после ухода с сервера, мы были заинтересованы во фракции ЛССД. Так и зародилось тогда это' +
      ' "сообщество". Какое-то время я был капитаном тюремной коррекции, а после перешёл в СЕБ. Потом я попал в' +
      ' состав к Фиолетиву тоже на гамбите и познакомился со многими ребятами, с которыми до сих пор в составе.' +
      ' Впрочем я рад что нахожусь в этом составе, ведь я был в разных "спецназёрских" стаках, а этот, честно' +
      ' говоря - самый лучший. \n' +
      'Играл в патрульном, ГЕД, ГНД, РХД, Спецназ.',
    vk: 'https://vk.com/palmangels86',
    youtube: 'https://www.youtube.com/legendary',
    tg: 'https://t.me/legendary86114',
    ds: 260694680038014978,
    about: 'inst: okolelov.0' +
      'вахтовик, бизнесмен, филантроп и начинающий миллионер ',
    place: 'Российская Федерация, ХМАО, Сургут',
  },
  {
    id: 3,
    category: 'Officers',
    nickname: 'Oper',
    rank: 'Police Officer III',
    image: '/assets/images/members/Oper.png',
    age: 2004,
    name: 'Артём',
    place: 'Российская Федерация, Полуостров Крым',
    server: 'SW, PCP, LSRP, GRP, GTA:W, GTA:W RU',
    experience: '6 лет',
    youtube: 'https://www.youtube.com/channel/UCxrUSBqSgbDM_u9eXxntRXA',
    ds: 765249845338308649,
  },
  {
    id: 4,
    category: 'Officers',
    nickname: 'raynott',
    rank: 'Police Officer III',
    image: '/assets/images/members/Raynott.png',
    age: 2005,
    name: 'Александр',
    server: 'GRP, LSRP, LSST, LSW, GTA:W RU, GTA:W EN, SA Stories',
    experience: '6 лет',
    aboutGta: 'Мой игровой путь начался с версии 0.3e, с сервера, где я был в мафии. Затем я перешел на версию 0.3.7 и оказался на Advance RP Orange, где был в группах под лидерством Федерико Лопеза. Позже я перешел на RP-GameWorld, где продолжительное время играл бизы. После этого я перешел на Revent RolePlay и Evolve RolePlay, что позволило мне завести множество знакомств. Эти связи в конечном итоге привели меня на серверы с повышенным ролевым режимом. Познакомился с нынешним стаком еще Los Santos CopChase в SAMP, и впоследствии, после открытия GTAW:RU я попал в СВАТ. В быстрое время обрёл тут много друзей, с которыми я открыто могу советоваться даже на какие-то личные для себя темы.',
    vk: 'https://vk.com/raynott',
    youtube: 'https://www.youtube.com/@raynott',
    tg: 'https://t.me/raynottt',
    ds: 440193115631124480,
    about: ``,
    place: 'Российская Федерация, Ярославль'
  },
  {
    id: 5,
    category: 'Officers',
    nickname: 'hixz',
    rank: 'Police Officer III',
    image: '/assets/images/members/hixz.png',
    age: 2006,
    name: 'Вадим',
    about: '',
    youtube: 'https://www.youtube.com/@hixzz',
    place: 'Российская Федерация, Псков',
    ds: 345615593455616010,
  },
  {
    id: 6,
    category: 'Officers',
    nickname: 'tuberkulek',
    rank: 'Police Officer III',
    image: '/assets/images/members/tuberkulek.png',
    age: 2004,
    name: 'Игорян',
    server: 'SW, Gambit, GTA:W, GTA:W EN',
    experience: '7 лет',
    aboutGta: '',
    ds: 254628166717538304,
    place: 'Российская Федерация, Полуостров Крым'
  },
  {
    id: 7,
    category: 'Officers',
    nickname: 'CottonCandy',
    rank: 'Police Officer III',
    image: '/assets/images/members/CottonCandy.png',
    age: 2005,
    name: 'Алан',
    server: 'Gambit, GTA:W, SW',
    experience: '7 лет.',
    about: 'Работал в разных сферах, от общепита до практики в государственных структурах, на сегодняшний день являюсь абитуриентом в одну из Лётных Школ Европы будучи студентом Авиакомпании. В наши дни стараюсь практиковать Английский язык, благодаря которому сдал и получил сертефикат IELTS на overall score 6.5, практикуюсь в сфере авиации и планирую связать свою жизнь с ней.',
    aboutGta: 'Обычный игрок из состава, играю в РП довольно с раннего возраста. Играл в разных сферах, как в крайме так и в области государственных структур. Начинал свой опыт в далеком 2018, старался играть в основном в гос.структурах, но в целом был и опыт играть криминальные организации. Увлекался шутерами по типу, Arma 3, Squad(играл в разных отрядах и серверах, включая давний Тушино), плотно интересовался сферой специальных-подразделений и их составляющей. Попал в 114 благодаря случайным обстоятельствам, познакомившись с Сашей Рейнотом и Хикзом, которые в дальнейшем ставили меня на правильный путь, и подсказывали в трудные и спорные моменты.',
    ds: 247233492738506752,
    place: 'Республика Казахстан, Алматы',
  },
  {
    id: 8,
    category: 'Officers',
    nickname: 'menefr3go',
    rank: 'Police Officer III',
    image: '/assets/images/members/menefrego.png',
    age: 2003,
    name: 'Владимир',
    server: 'SW Project, Gambit RP, LS:RP, LS:RP RU, LSS, STALKER Project, Medival RP, PCP, RPW, GTA-W, GTA:W LC, GTA:W EN.',
    experience: '8 лет',
    aboutGta: 'Начал со всеми известных RPG-серверов, ещё в лохматые года. Role-Play как режим открыл для себя' +
      ' совершенно случайно, благодаря товарищам из зарубежного сообщества. Попробовал себя на малоизвестных европейских проектах без закрытой регистрации. Полноценно втянулся уже на русскоязычных площадках. \n' +
      'Успел поиграть и перенять опыт предшественников повсеместно, никогда не причислял себя к определённому ' +
      'сегменту. \n' +
      '\n' +
      'Если говорить об опыте игры правоохранителя, то с особым трепетом и любовью всегда относился к реализации ролей ' +
      'в рамках следовательской деятельности. Возглавлял всевозможные детективные детали в разные временные промежутки. \n' +
      'В спецназе оказался относительно недавно, молодая кровь. \n' +
      '\n' +
      'Имел опыт пребывания на позиции серверного менеджера некогда крупнейшего ролевого проекта с закрытой регистрации.',
    ds: 394930178389966848,
    about: 'Среднестатистический миллениал. Работаю в сфере рекламы - на дистанционной основе и не только' +
      ', действующий SMM Team Lead. Мотивирован безбедным настоящим и надеюсь на перспективы в будущем. Кое-как' +
      ' извиваюсь и стараюсь не отчислиться из университета. Маневрирую в ритмах затяжной миграции. Перманентно' +
      ' пребываю в состоянии адаптации. Как и многие – любитель зависнуть в игрушках, лишний раз отвлекаюсь от ' +
      'житейской рутины за монитором. Практикую и изучаю английский, интерес подпитывает влечение к западной культуре.',
    place: 'Республика Казахстан, Астана',
  },
  {
    id: 9,
    category: 'Officers',
    nickname: 'SamWall',
    rank: 'Police Officer III',
    image: '/assets/images/members/Samwall.png',
    age: 2001,
    name: 'Самвел',
    server: 'GTAW EN, GTAW RU LSRP, SW, STAGE, PACIFIC, GAMBIT',
    experience: '7 лет',
    aboutGta: '',
    ds: 602464894687641600,
    youtube: 'https://www.youtube.com/channel/UCM2qW0POrUBVVOxzequabrA',
    place: 'Российская Федерация, Полуостров Крым',
  },
  {
    id: 10,
    category: 'Officers',
    nickname: 'dustyy.',
    rank: 'Police Officer III',
    image: '/assets/images/members/dusty.png',
    age: 2005,
    name: 'Илья',
    server: '',
    experience: '7 лет',
    aboutGta: '',
    ds: 348517217865957397,
    about: '',
    place: 'Российская Федерация, Москва',
  },
  {
    id: 11,
    category: 'Officers',
    nickname: 'ogmamatraxer',
    rank: 'Police Officer III',
    image: '/assets/images/members/menefrego.png',
    age: 2000,
    name: 'Алексей',
    server: 'Classic, G-RP, LSRP, LSRL, SW, GTA-W, Valakas',
    experience: '9-10 лет.',
    aboutGta: '7 лет. LSPD, LSSD. DB: GND, RHD, CGHD, VICE, MCD. MP: C, D Platoon, SEB.',
    ds: 295641015056990208,
    about: 'Кто знает, тот знает. Всем знакомым плотный салам.',
    place: 'Грузия, Тбилиси',
  },
  {
    id: 12,
    category: 'Officers',
    nickname: 'dontkillme',
    rank: 'Police Officer III',
    image: '/assets/images/members/dontkillme.png',
    age: 2007,
    name: 'Дима',
    server: 'GRP, LSST, LSW, GTA:W RU, GTA:W EN, SA Stories, LSRP',
    experience: '6 лет',
    aboutGta: `Один из прородителей СА Сториса, отвечал за работоспособность и содержание веб-части, могу быть известен вам как один из разработчиков, "укравших мод и базу данных".` +
    '\nА так, в целом, за 6 лет своей игры пытался искать себя в разных сферах, но по итогу понял, что максимально адекватно могу играть только в сеттинге ЛА, любые другие всерьез не воспринимаю.',
    ds: 531462564304125962,
    youtube: 'https://www.youtube.com/@dontkillmeddx',
    about: '',
    place: 'Российская Федерация, Москва(?)',
  },
  {
    id: 13,
    category: 'Officers',
    nickname: 'Zik',
    rank: 'Police Officer III',
    image: '/assets/images/members/zik.png',
    age: 2003,
    name: '',
    server: 'GTA:W RU',
    experience: '',
    aboutGta: '',
    ds: 396056445663313931,
    place: 'Российская Федерация',
  },
  {
    id: 14,
    category: 'Officers',
    nickname: 'denson',
    rank: 'Police Officer III',
    image: '/assets/images/members/denson.png',
    age: 2003,
    name: 'Владислав',
    server: 'Stage, Pacific, LSL, SW, Gambit, GTAW',
    experience: '6 лет',
    tg: 'https://t.me/ossp114',
    ds: 1148713775738925137,
    about: 'Подпишите контракт с мин. обороны тел: 117',
    place: 'Российская Федерация, Боровичи'
  },
  {
    id: 15,
    category: 'Officers',
    nickname: 'blobus',
    rank: 'Police Officer III',
    image: '/assets/images/members/blobus.png',
    age: 2004,
    name: 'Александр',
    server: '',
    experience: '',
    ds: 595628025555451905,
    about: '',
    place: 'Российская Федерация, Челябинск',
  },
  {
    id: 16,
    category: 'Officers',
    nickname: 'sanitarlesa.',
    rank: 'Police Officer III',
    image: '/assets/images/members/dudek.png',
    age: 2000,
    name: 'Вячеслав',
    server: '',
    experience: '',
    ds: 455620816714072091,
    about: '',
    place: 'Российская Федерация',
  },
  {
    id: 17,
    category: 'Officers',
    nickname: 'stupzz',
    rank: 'Police Officer III',
    image: '/assets/images/members/stupzz.png',
    age: 2000,
    name: 'Андрей',
    server: '',
    experience: '',
    ds: 338727325464264705,
    about: '',
    place: 'Российская Федерация',
  },
  {
    id: 18,
    category: 'Officers',
    nickname: 'k1ngseventeen',
    rank: 'Police Officer III',
    image: '/assets/images/members/king17.png',
    age: 2004,
    name: "Александр",
    server: '',
    experience: '',
    ds: 1290145042912247818,
    about: '',
    place: 'Российская Федерация',
  },
]

export const retired: TMember[] = [
  {
    id: 1,
    category: 'Retired',
    nickname: 'Morgan',
    rank: 'Lieutenant I',
    retired: true,
    age: 1998,
    name: 'Егор',
  },
  {
    id: 2,
    category: 'Retired',
    nickname: 'Ozz',
    rank: 'Sergeant I',
    retired: true,
    age: 1000,
    name: 'Никита',
  },
  {
    id: 3,
    category: 'Retired',
    nickname: 'Monk',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Эмиль',
  },
  {
    id: 4,
    category: 'Retired',
    nickname: 'henper',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Владислав',
  },
  {
    id: 5,
    category: 'Retired',
    nickname: 'dziczek',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Сергей',
  },
  {
    id: 6,
    category: 'Retired',
    nickname: 'Don',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Кемран',
  },
  {
    id: 7,
    category: 'Retired',
    nickname: 'Hiki',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Александра',
  },
  {
    id: 8,
    category: 'Retired',
    nickname: 'Samurai',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Рустам',
  },
  {
    id: 9,
    category: 'Retired',
    nickname: 'Burket',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 10,
    category: 'Retired',
    nickname: 'Denya',
    rank: 'Police Officer II',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 11,
    category: 'Retired',
    nickname: 'vvodolei',
    rank: 'Police Officer II',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 12,
    category: 'Retired',
    nickname: 'deika',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 13,
    category: 'Retired',
    nickname: 'brom',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: 'Никита',
  },
  {
    id: 14,
    category: 'Retired',
    nickname: 'ragim',
    rank: 'Police Officer III',
    retired: true,
    age: 2003,
    name: 'Рагим',
  },
  {
    id: 15,
    category: 'Retired',
    nickname: 'zoofeel',
    rank: 'Police Officer III',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 16,
    category: 'Retired',
    nickname: 'cornelwest',
    rank: 'Police Officer II',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 17,
    category: 'Retired',
    nickname: 'Yan Grimm',
    rank: 'Police Officer II',
    retired: true,
    age: 1000,
    name: 'Ян',
  },
  {
    id: 18,
    category: 'Retired',
    nickname: 'Enokewa',
    rank: 'Police Officer II',
    retired: true,
    age: 1000,
    name: '',
  },
  {
    id: 19,
    category: 'Retired',
    nickname: 'bl4ss',
    rank: 'Police Officer II',
    retired: true,
    age: 2008,
    name: '',
  },
]

export const executed: TMember[] = [
  {
    id: 1,
    category: 'Executed',
    nickname: 'dei',
    rank: 'Police Officer III',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 2,
    category: 'Executed',
    nickname: 'Wayden G',
    rank: 'Police Officer III',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 3,
    category: 'Executed',
    nickname: 'ukini',
    rank: 'Police Officer III',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: 'Илья',
  },
  {
    id: 4,
    category: 'Executed',
    nickname: 'Walker',
    rank: 'Police Officer III',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 5,
    category: 'Executed',
    nickname: 'Jonya',
    rank: 'Police Officer III',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 6,
    category: 'Executed',
    nickname: 'Ovchinnikov',
    rank: 'Police Officer II',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 7,
    category: 'Executed',
    nickname: 'Sky',
    rank: 'Police Officer II',
    image: '/assets/images/members/executed.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 8,
    category: 'Executed',
    nickname: 'Plushka 3-5-4',
    rank: 'Police Officer III',
    image: '/assets/images/members/tuberkulek.png',
    executed: true,
    age: 1000,
    name: '',
  },
  {
    id: 9,
    category: 'Executed',
    nickname: 'Murawskiy',
    rank: 'Police Officer III',
    image: '/assets/images/members/tuberkulek.png',
    executed: true,
    age: 1000,
    name: 'Антон',
  },
]

export const allMembers = [
  ...supervisors, ...juniors, ...retired, ...executed
]
