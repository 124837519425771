import React, {FC} from 'react';
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes";
import Header from "./components/Header";
import Preloader from "../componets/Preloader";
import BreadCrumb from "../componets/BreadCrumb";
import {ThemeProvider} from "styled-components";
import theme from '../shared/styles/theme';
import Styled from './styled';

const App: FC = () => {
  return (
    <>
      <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Preloader />
        <Header/>
        <Styled.Wrapper>
          <BreadCrumb/>
        </Styled.Wrapper>
        <Routes/>
      </ThemeProvider>
    </BrowserRouter>
    </>
  );
}

export default App;
