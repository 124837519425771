import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1480px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
`
const MobileWrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
    span {
      font-weight: bolder;
    }
`

export default {
  Wrapper,
  MobileWrapper,
}
