import React from 'react';
import Styled from './styled';
import {Links} from '../../shared/consts/videosData';
import { VideoCard } from 'componets/VideoCard';

const Gallery = () => {
    return (
        <Styled.GalleryWrapper>
            <Styled.GalleryHeader>Bodycam footage</Styled.GalleryHeader>
            <Styled.GalleryVideos>
            {Links.length > 0 ? (
                Links.map((video, index) => (
                    <VideoCard 
                        key={index}
                        src={video.src}
                        title={video.title || ''}
                    />
                ))
            ) : (
                <Styled.GalleryLoadingText>Здесь еще нет видео</Styled.GalleryLoadingText>
            )}
            </Styled.GalleryVideos>
        </Styled.GalleryWrapper>
    )
}

export default Gallery;