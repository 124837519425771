import styled, {css} from 'styled-components'
import {Card} from 'antd'
import {MouseEventHandler} from "react";

type MemberCardTypes = {
  onClick?: MouseEventHandler
}

const MemberCard = styled.div<MemberCardTypes>`
  
  .ant-card {
    background: linear-gradient(123deg, rgb(87 87 87) 0%, rgb(101 97 97) 100%)
  }

  .ant-card-bordered {
    border: none;
  }
`

const NotStyledCard = styled(Card)`
  transition: all 0.3s ease;
  font-family: "Pixel", sans-serif !important;
  
  img {
    max-height: 300px;
  }
  
  :where(.css-dev-only-do-not-override-10ed4xt).ant-card-hoverable:hover {
    transform: translate(0, -10px);
  }
  
`

export default {
  MemberCard,
  NotStyledCard,
}
