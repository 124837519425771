import styled, { css } from 'styled-components';

type PreviewTypes = {
  loaded?: boolean
}

const Wrapper = styled.div<PreviewTypes>`
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  opacity: 1;
  transition: all 2s ease;
  
  ${(props) => props.loaded && css`
    opacity: 0;
    transform: translateY(-100%);
  `};
`;

const Logo = styled.img`

`;

export default {
  Wrapper,
  Logo,
};
