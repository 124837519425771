import styled, {css} from 'styled-components'
import Colors from 'shared/styles/colors'

type Types = {
  juniors?: boolean,
  supervisors?: boolean,
  retired?: boolean,
  executed?: boolean,
}

const MembersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-bottom: 20px;
    
    @media (max-width: 1080px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 630px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 430px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const MembersListTitle = styled.h1<Types>`
  text-align: center;
  font-weight: 700;
  background: rgb(48,59,113);
  background: linear-gradient(135deg, #2C3E50 0%, #7F8C8D 100%);
  box-shadow: 10px 10px 5px 0 rgba(0,0,0,0.75);
  max-width: 235px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  color: ${Colors.white};
    
    ${(props) => props.juniors && css`
        background: linear-gradient(135deg, #0080FF 0%, #00AFFF 100%) !important;  // Синий градиент
    `}
    ${(props) => props.supervisors && css`
        background: linear-gradient(135deg, #660000 0%, #990000 100%) !important;  // Темно-красный градиент
    `}
    ${(props) => props.retired && css`
        background: linear-gradient(135deg, #000000 0%, #333333 100%) !important;  // Черный градиент
    `}
    ${(props) => props.executed && css`
        background: linear-gradient(135deg, #2a2a2a 0%, #4d4d4d 100%) !important;  // Очень темный серый градиент
    `}

    a {
      color: ${Colors.white};
    }
`
export default {
  MembersWrapper,
  MembersListTitle,
}
