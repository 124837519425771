import styled from 'styled-components';
import Colors from 'shared/styles/colors';

const VideoCardWrapper = styled.div`
    &:last-of-type {
        margin-right: 0;
    }
    background: ${Colors.white};
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const VideoCardTitle = styled.span`
    text-align: center;
    font-weight: 700;
    padding: 10px 0;
    font-size: 18px;
`;

const VideoCardImage = styled.img`
    display: block;
    width: 100%;
    padding: 0;
    border-radius: 6px 6px 0 0;
`;

const VideoCardAuthor = styled.span`
    color: ${Colors.gray2};
    display: flex;
    width: 100%;
    padding: 20px 10px;

    @media (max-width: 630px) {
        padding: 10px;
    }
`;

const VideoCardButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const VideoCardButton = styled.button`
    background: linear-gradient(166deg, rgb(48, 59, 113) 17%, rgb(43, 47, 66) 100%);
    color: white;
    width: 100%;
    padding: 10px;
    border-radius: 15px;
    transition: transform 0.5s ease;
    margin: 10px;
    user-select: none;
    &:hover {
        background: linear-gradient(90deg, rgb(48, 59, 113) 17%, rgb(43, 47, 66) 100%);
    }
    &:active {
        transform: scale(0.91);
    }
`;

const VideoModal = styled.div`
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: black 1px 1px 10px;
    background: ${Colors.lightdark};
    width: 50%;
    z-index: 9998;
    opacity: 1;

    @media (max-width: 630px) {
        width: 100%;
    }
`;

const VideoPlayerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    overflow: hidden;
`;
const VideoPlayerIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const VideoModalHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
const VideoModalCloseButton = styled.button`
    font-size: 40px;
    color: ${Colors.gray1};
    transition: .3s;
        &:hover {
            color: ${Colors.white};
        }
`
const FadeOut = styled.div`
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
    background: #00000094;
    z-index: 99999999;
`
const VideoModalTitle = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
`

export default {
    VideoCardWrapper,
    VideoCardTitle,
    VideoCardImage,
    VideoCardAuthor,
    VideoCardButton,
    VideoCardButtonContainer,
    VideoModal,
    VideoPlayerWrapper,
    VideoPlayerIframe,
    VideoModalCloseButton,
    VideoModalHeader,
    FadeOut,
    VideoModalTitle,
};
