import { css } from 'styled-components';
import Colors from "./colors";

const Normalize = css`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  #root {
    background-color: ${Colors.dark};
    background-image: url("/assets/images/background.png");
  }
  
  body {
    margin: 0;
    padding: 0;
    height: fit-content;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: #828282;
    font-family: "Manrope", sans-serif;

    &::-webkit-scrollbar {
      background: #323232;
      width: 13px;
      height: 15px;
    }


    &::-webkit-scrollbar-thumb {
      background: #828282;
    }
  }

  svg {
    outline: none;
  }

  a {
    outline: none;
    text-decoration: none;
    color: #0088cc;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: .6;
    }
  }

  button, a {
    cursor: pointer;
  }

  button {
    background: none;
    border: none;

    &:focus {
      outline: none;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  input, textarea {
    border: none;
    outline: none;
    border-radius: 0;
    resize: none;
  }
`;

export default Normalize;
