import React, {useMemo} from 'react';
import {Card} from 'antd';
import Styled from './styled'
import { useNavigate } from "react-router-dom";

const {Meta} = Card;

const MemberItem = (props: any) => {

  let ImageSrc: string = useMemo(() => {
    if (props.member.executed) {
      return "/assets/images/members/executed.png";
    } else if (props.member.retired) {
      return "/assets/images/members/retired.png";
    } else {
      return props.member.image;
    }
  }, [props.member.executed, props.member.retired, props.member.image])

  const navigate = useNavigate()

  return (
    <Styled.MemberCard onClick={() => navigate(`/Members/${props.member.category}/${props.member.nickname}`)}>
      <Styled.NotStyledCard
        hoverable
        cover={<img alt="#" src={ImageSrc} draggable='false' />}
      >
        <Meta title={props.member.nickname} description={props.member.rank}/>
      </Styled.NotStyledCard>
    </Styled.MemberCard>
  );
};

export default MemberItem;
