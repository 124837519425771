import React from 'react';
import MembersItem from "../MemberItem";
import Styled from './styled';

const CATEGORIES = {
    SUPERVISORS: 'Supervisors',
    OFFICERS: 'Officers',
    RETIRED: 'Retired',
    EXECUTED: 'Executed',
};

const MemberList = ({ members, title }: any) => {
    const categoriesPresence = members.reduce((acc: Record<string, boolean>, member: { category: string }) => {
        if (Object.values(CATEGORIES).includes(member.category)) {
            acc[member.category] = true;
        }
        return acc;
    }, {});

    return (
        <>
            <Styled.MembersListTitle
                juniors={categoriesPresence[CATEGORIES.OFFICERS]}
                supervisors={categoriesPresence[CATEGORIES.SUPERVISORS]}
                retired={categoriesPresence[CATEGORIES.RETIRED]}
                executed={categoriesPresence[CATEGORIES.EXECUTED]}
            >
                {title}
            </Styled.MembersListTitle>
            {members && (
                <Styled.MembersWrapper>
                    {members.map((member: any) => (
                        <MembersItem member={member} key={member.id} />
                    ))}
                </Styled.MembersWrapper>
            )}
        </>
    );
};

export default MemberList;
