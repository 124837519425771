import React from 'react';
import Styled from './styled'

const NotFoundPage = () => {
  const [ip, setIp] = React.useState(NaN);
  const getIp = () => {
    fetch('https://api64.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      setIp(data.ip);
    })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });
  }
  const getCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some(device => device.kind === 'videoinput');

      if (!hasCamera) {
        return false;
      } else if (hasCamera) {
        navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        return true;
      }
    } catch (error) {
      console.error('Error checking camera:', error);
      return false;
    }
  }
  getIp();
  getCamera();
  return (
    <>
      <Styled.Wrapper>
        <Styled.Text>Что тут ищешь?</Styled.Text>
        <Styled.Text>Ваше фото и IP (<span style={{ color: 'red' }}>{ip}</span>) было отправлено на обработку нашим специалистам.</Styled.Text>
        <Styled.Img src={'./assets/images/404/smile.png'} />
      </Styled.Wrapper>
    </>
  );
};

export default NotFoundPage;
