import React from 'react';
import Styled from './styled'
import MemberList from "../../../componets/MemberList";
import { juniors } from '../../../shared/consts/membersData';

const Members = () => {
  return (
    <Styled.Wrapper>
      <MemberList members={juniors} title={'Field Staff'}/>
    </Styled.Wrapper>
  );
};

export default Members;
