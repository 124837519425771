export type Videos = {
    src: string;
    title?: string;
}

export const Links: Videos[] = [
    {
        src: '01YtaMp0b-Q',
    },
    {
        src: '8AR5Ia3EDtM',
    },
    {
        src: 'jS_Mk1e6Ffo',
    },
    {
        src: 'tsP9lDFAvpk',
    },
    {
        src: 'N26wWbEU3UA',
    },
    {
        src: 'A639ce1SX6s',
    },
    {
        src: 'fPRosQLqN2Y',
    },
    {
        src: 'm5c4oNA6B_I',
    },
    {
        src: 'PpOaeKxKNFQ',
    },
    {
        src: 'UQNbPe3UqBQ',
    },
    {
        src: '1-8Xi1Iex-g',
    },
    {
        src: '6wi6HMUT_s4',
    },
    {
        src: 'aunvay0acDc',
    },
    {
        src: 'p2MwuHe_SQE',
    },
    {
        src: 'YdzkcOhB8bE',
    },
    {
        src: 'HGG-hWIiu5k',
    },
    {
        src: 'p4aV2paAUYk',
    },
    {
        src: 'ROqGkUIC7wU',
    },
    {
        src: 'iKFWbfsa03o',
    },
    {
        src: 'KeOQZ94DnCE',
    },
    {
        src: 'hrsx7BnV2Jc',
    },
]