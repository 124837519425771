import React from 'react';
import Styled from './styled'
import MemberList from "../../../componets/MemberList";
import { retired } from '../../../shared/consts/membersData';

const Members = () => {
  return (
    <Styled.Wrapper>
      <MemberList members={retired} title={'Retired'}/>
    </Styled.Wrapper>
  );
};

export default Members;
