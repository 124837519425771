import Colors from 'shared/styles/colors'
import styled from 'styled-components'

const GalleryWrapper = styled.section `
    padding: 200px 20px 0 20px;
    max-width: 1480px;
    margin: 0 auto;
    min-height: 100vh;
`
const GalleryVideos = styled.div `
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
        

    @media (max-width: 630px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 430px) {
        grid-template-columns: repeat(1, 1fr);
    }
`
const GalleryHeader = styled.h1`
  text-align: center;
  font-weight: 700;
  background: rgb(48,59,113);
    background: linear-gradient(135deg, #2C3E50 0%, #7F8C8D 100%);
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  max-width: 235px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 10px;
  color: ${Colors.white};
  pointer-events: none;
  user-select: none;
`
const GalleryLoadingText = styled.h1`
    text-align: center;
    color: ${Colors.white};
    width: 100%;
`

export default {
    GalleryWrapper,
    GalleryVideos,
    GalleryHeader,
    GalleryLoadingText,
}