const Colors = {
  placeholderColor: '#a9a9a9',
  gray0: '#fdfdfd',
  gray1: '#91969d',
  gray2: '#6f7072',
  gray3: '#d8dce5',
  turquoise: '#DCF9FB',
  turquoiseDark: '#0DC4E0',
  turquoiseDark2: '#00A8C2',
  dark: '#121416',
  lightdark: '#2d3033',
  white: '#FFFFFF',
  red: '#FF513D',
  green: '#00B259',
  mainBg: '#303030',
  orangeGrad: 'linear-gradient(90deg, #EC6E72 0%, #FF6A66 35.37%, #FBAD43 100.41%);',
  blueGrad: 'linear-gradient(90deg, #6E73EC 0%, #66DAFF 41.49%, #4360FB 100.41%);'
};

export default Colors;
