import styled from "styled-components";
import { Breadcrumb } from "antd";
import Colors from "../../shared/styles/colors";

const BreadWrapper = styled(Breadcrumb)`
  position: absolute;
  top: 135px;
  z-index: 100000;
    

  :where(.css-dev-only-do-not-override-1me4733).ant-breadcrumb a {
    font-size: 15px;
    opacity: 0.7;
    font-family: "Manrope", sans-serif;
  }

  :where(.css-dev-only-do-not-override-1me4733).ant-breadcrumb li:last-child {
    font-size: 15px;
    color: ${Colors.white};
    font-family: "Manrope", sans-serif;
    cursor: default;
  }
  :where(.css-dev-only-do-not-override-1me4733).ant-breadcrumb .ant-breadcrumb-separator {
    color: ${Colors.white};
  }
  a, span {
    color: ${Colors.white};
  }
  a:hover {
    color: ${Colors.gray1};
  }
  .ant-breadcrumb-separator {
    color: ${Colors.white};
  }
`

export default {
  BreadWrapper,
}
