import React from 'react';
import Styled from './styled'
import MemberList from "../../componets/MemberList";
import { supervisors, juniors, retired, executed } from '../../shared/consts/membersData';

const Members = () => {
  return (
    <Styled.MembersWrapper>
      <MemberList members={supervisors} title={<a href="/members/Supervisors">Supervisors</a>}/>
      <MemberList members={juniors} title={<a href="/members/Officers">Field Staff</a>}/>
      <MemberList members={retired} title={<a href="/members/Retired">Retired</a>}/>
      <MemberList members={executed} title={<a href="/members/Executed">Executed</a>}/>
    </Styled.MembersWrapper>
  );
};

export default Members;
