import styled, {css} from 'styled-components'

type TextTypes = {
  textAllignStart?: boolean,
  gradient?: boolean,
}

const ProfileWrapper = styled.section`
  padding: 200px 20px 0 20px;
  max-width: 1480px;
  margin: 0 auto;
  min-height: 100vh;
`

const ProfileBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
`

const Avatar = styled.img`
  max-width: 300px;
  max-height: 300px;
  box-shadow: 10px 10px 10px 0 rgba(0,0,0,0.75);
  margin-right: 50px;

  @media (max-width: 900px) {
    margin: 0 0 20px 0;
  }
`

const InfoBox = styled.div`
  width: 100%;
  display: flex;
  
  div {
    &:first-of-type {
      margin-right: 45px;
      
      @media (max-width: 900px) {
        margin-right: 0;
      }
    }
  }
  
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const ServerText = styled.div`
  font-weight: 400;
  color: #a0a0a0;
  font-size: 20px;
  line-height: 35px;
  display: flex;
  margin-bottom: 10px;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    padding-left: 40px;
    padding-top: 5px;
  }

  li {
    box-sizing: border-box;
    padding: 0 5px 5px 5px;
    list-style: disc;
    font-size: 16px;
    width: 100%;
    
    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
  
  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Text = styled.div<TextTypes>`
  font-weight: 400;
  color: #a0a0a0;
  font-size: 20px;
  line-height: 35px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  
  ${(props) => props.gradient && css `
    background: linear-gradient(135deg, #2C3E50 0%, #7F8C8D 100%);
    width: fit-content;
    padding: 5px 10px;
    border-radius: 15px;
    margin: 10px 0;
  `}
`

const Link = styled.a`
  font-weight: 700;
  color: gold;
  margin-left: 5px;
  display: flex;
`
const DSLink = styled.a`
  display: flex;
  margin-left: 5px;
    svg {
      fill: #5662f6;
    }
`

const ProfileTop = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const ProfileBottom = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`

export default {
  ProfileWrapper,
  ProfileBox,
  Avatar,
  InfoBox,
  Text,
  ServerText,
  Link,
  ProfileTop,
  ProfileBottom,
  DSLink,
}
