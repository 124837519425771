import styled from 'styled-components'

const Wrapper = styled.section`
  padding: 200px 20px 0 20px;
  max-width: 1480px;
  margin: 0 auto;
  min-height: 100vh;
`

const Img = styled.img`
  width: 25%;
  margin: 0 auto;
  display: flex;
`

const Text = styled.div`
  color: ${(props) => props.theme.Colors.white};
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`

export default {
  Wrapper,
  Img,
  Text,
}
