import styled, {css} from "styled-components";
import { Link as NotStyledLink } from 'react-router-dom'

type ListTypes = {
  retired?: boolean
  executed?: boolean
  supervisor?: boolean;
}

const MainWrapper = styled.section`
  margin: 0 auto;
  min-height: 100vh;
`

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 125px;
  .ant-timeline-item-tail {
    background-color: #000000;
    padding-bottom: 50px;
  }
  .ant-timeline-item-content {
    color: #FFFFFF;
    font-weight: 700;
    inset-block-start: -5px;
  }
  
  .ant-timeline-item-label {
    font-weight: 700;
    color: #FFFFFF;
    padding-right: 9px;
  }
  
  .anticon-spin {
    svg {
      width: 15px;
      height: 15px;
      fill: #000000;
    }
  }
  a {
    font-weight: 700;
  }
`

const BgImage = styled.img`
  max-width: 1400px;
  margin: 0 auto;
  max-height: 800px;
  width: 100%;
`

const TextWrapper = styled.div`
  max-width: 1400px;
  margin: 50px auto;
  width: 100%;
  display: flex;
  @media (max-width: 1440px) {
    padding: 0 10px;
  }

  @media (max-width: 630px) {
    flex-direction: column;
  }
`

const Title = styled.h2`
  color: ${(props) => props.theme.Colors.white};
  font-weight: 700;
  margin-bottom: 5px;
`

const Text = styled.div`
  color: ${(props) => props.theme.Colors.white};
  margin-bottom: 10px;
`

const TextBox = styled.div`
  width: 50%;
  overflow: hidden;
  @media (max-width: 630px) {
    width: 100%;
  }
`

const List = styled.ul`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
`

const ListItem = styled.li`
  padding: 3px;
  font-weight: 700;
`

const ListLink = styled(NotStyledLink)<ListTypes>`
  ${(props) => props.retired && css`
    color: ${(props) => props.theme.Colors.gray2};
  `}
  ${(props) => props.executed && css`
    color: rgb(111 112 114 / 54%);
    text-decoration: line-through;
  `}
  ${(props) => props.supervisor && css`
    color: #660000;
    &:hover {
      color: red;
    }
  `}
`

const Box = styled.div`
  margin: 35px 0;
  &:first-of-type {
    margin: 0 0 35px;
  }
`


export default {
  MainWrapper,
  MainBox,
  BgImage,
  TextWrapper,
  Title,
  Text,
  TextBox,
  List,
  ListItem,
  ListLink,
  Box,
}
