import styled from 'styled-components'

const Wrapper = styled.section`
  max-width: 1480px;
  padding: 150px 20px 0 20px;
  margin: 0 auto;
  min-height: 100vh;
`

export default {
  Wrapper,
}
