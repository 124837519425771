import React, { FC, useState } from 'react';
import Styled from './styled';

const Preview:FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  setTimeout(() => {
    setLoading(false);
    localStorage.setItem('loaded', 'true');
  }, 1300);

  return (
    <Styled.Wrapper loaded={!loading}>
      <Styled.Logo src="/assets/images/preloader/metroliasion.png" />
    </Styled.Wrapper>
  );
};

export default Preview;
