import { createGlobalStyle } from 'styled-components';
import Normalize from './normalize';
import Fonts from "./fonts";

const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Fonts};
`;

export default GlobalStyle;
