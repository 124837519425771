import React, {useMemo, useState} from 'react';
import Styled from './styled'
import {Timeline, Tooltip} from 'antd';

const Main = () => {

  const [mode, setMode] = useState<'left' | 'alternate' | 'right'>('left');

  const options = ['Show', 'Hide', 'Center'];
  const [arrow, setArrow] = useState('Show');

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <Styled.MainWrapper>
      <Styled.MainBox>
        <Styled.BgImage src={'./assets/images/main/main-bg.png'} loading='eager' />
        <Styled.TextWrapper>
          <Styled.TextBox>
            <Styled.Box>
              <Styled.Title>С чего все начиналось?</Styled.Title>
              <Styled.Text>
                К концу 2013 года Моргана ставят на главу Platoon D, там же собирается первый костяк игроков.
                <Styled.List>
                  <Styled.ListItem>
                    <Styled.ListLink
                      retired
                      to={'Members/Retired/Morgan'}
                    >
                      Morgan
                    </Styled.ListLink>
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      retired
                      to={'Members/Retired/Ozz'}
                    >
                      Ozz</Styled.ListLink>
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      supervisor
                      to={'Members/Supervisors/FIOLETIV'}
                    >
                      FIOLETIV
                    </Styled.ListLink
                   >
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      retired
                      to={'Members/Retired/Don'}
                    >
                      Don
                    </Styled.ListLink>
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      retired
                      to={'Members/Retired/Enokewa'}
                    >
                      Enokewa
                    </Styled.ListLink>
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      executed
                      to={'Members/Executed/dei'}
                    >
                      dei
                    </Styled.ListLink>
                  </Styled.ListItem>
                  <Styled.ListItem>
                    <Styled.ListLink
                      executed
                      to={'Members/Executed/Walker'}
                    >
                      Walker
                    </Styled.ListLink>
                  </Styled.ListItem>
                </Styled.List>
              </Styled.Text>
            </Styled.Box>
            <Styled.Box>
              <Styled.Title>Кого мы играем?</Styled.Title>
              <Styled.Text>
                Platoon D, или чаще можно слышать СВАТ или просто спецназ. Специальное подразделение входящее в Дивизию
                Метрополитан( METRO ) находящееся в Бюро Контр-терроризма и специальных операций полиции города
                Лос-Анджелес.
              </Styled.Text>
              <Styled.Text>
                Мы играем тот же прототип СВАТ, просто в реалиях GTA. Бывали случаи когда мы играли не конкретно спецназ
                ЛАПД
                (<Tooltip title={'Полицейский Департамент Лос-Сантоса'}> ЛСПД </Tooltip>)
                , а другого города
                (
                <Tooltip title={'Лас-Вентурас'}> ЛВ </Tooltip>,
                <Tooltip title={'Сан-Фиерро'}> СФ </Tooltip>,
                <Tooltip title={'Либерти-Сити'}> ЛС </Tooltip>
                )
                но в любом случае концепция нашей игры оставалась примерно одинаковой.
              </Styled.Text>
            </Styled.Box>
            <Styled.Box>
              <Styled.Title>Продолжение истории</Styled.Title>
              <Styled.Text>Далее по истечению каждого года прибавлялись новые люди, уходили старые.</Styled.Text>
              <Styled.Text>
                Появлялись первые бифы между сообществами, самое популярное это ЛСПД.Ру против ЛС-Гов. Не
                беря в учет бифы из-за сообществ, были бифы по разному пониманию игры спецназа между разными составами.
              </Styled.Text>
            </Styled.Box>
            <Styled.Box>
              <Styled.Title>Бифы</Styled.Title>
              <Styled.Text>
                Самый первый биф был с игроками Бронтелла, Нуар и Риверс которые в давние времена играли в ЛССД. Далее
                бифы перенеслись уже на другие составы спецназа. Например составы с Робертом Шутером, Григсом, Ниггачиксом и.т.д по списку.
              </Styled.Text>
              <Styled.Text>
                Основной причиной постоянных бифов был не уровень игры самих игроков, а понимание игры спецназа в
                принципе.
                Ребята из других составов пытались полностью копировать стиль игры спецназа с известного на тот момент
                западного сервера ЛСРП. Мы же наоборот пытались придерживаясь общей концепции и играть в свою игру.
                Мнения насчет игры не совпадали всегда.
              </Styled.Text>
            </Styled.Box>
            <Styled.Box>
              <Styled.Title>На момент 2023 года</Styled.Title>
              <Styled.Text>
                После игры продолжительностью более года на проекте GTA:W, в связи с пропажей интереса и отвратительным
                отношением со стороны администрации к нашему составу, было принято решение уйти на временный отдых.
                Наше место заняли ребята из спецназа ЛССД ( СЕБ ), во главе с СБУ и Сайберкэт. Отношение к их составу -
                нейтральное, если не брать во внимание пару личностей по отдельности, которые заработали себе плохую
                репутацию за свое поведение.
              </Styled.Text>
              <Styled.Text>
                Подробнее узнать с чем связан наш конфликт перед уходом непосредственно с ГА проекта на тот момент (
                Гэби ), вы можете по этой <a href="https://vk.com/extermination_rats_unit">ссылке</a>
              </Styled.Text>
            </Styled.Box>
            <Styled.Box>
              <Styled.Title>На момент 31.10.2024</Styled.Title>
              <Styled.Text>
                Ожидаем открытие сервера Role Play World, можете присоединиться так же
              </Styled.Text>
              <Styled.Text>
                <a href="https://discord.gg/78Gazwb8" target="_blank">DISCORD</a>
              </Styled.Text>
            </Styled.Box>
          </Styled.TextBox>
          <Styled.TextBox>
            <Timeline
              mode={mode}
              pending="Ждем..."
              items={[
                {
                  color: '#916ac6',
                  label: 'Just',
                  children: '2012 год',
                },
                {
                  color: '#916ac6',
                  label: 'Original, Classic',
                  children: '2013 год',
                },
                {
                  color: '#916ac6',
                  label: 'Classic SF, LSRL, SW Project',
                  children: '2014 год',
                },
                {
                  color: '#916ac6',
                  label: 'Original, SW Project, LSP',
                  children: '2015 год',
                },
                {
                  color: '#916ac6',
                  label: '.devGaming, LSRP',
                  children: '2016 год',
                },
                {
                  color: '#916ac6',
                  label: 'San Fierro Stories',
                  children: '2017 год',
                },
                {
                  color: '#916ac6',
                  label: 'Gambit',
                  children: '2018 год',
                },
                {
                  color: '#916ac6',
                  label: 'Gambit',
                  children: '2019 год',
                },
                {
                  color: '#916ac6',
                  label: 'Gambit',
                  children: '2020 год',
                },
                {
                  color: '#916ac6',
                  label: 'GTA:W',
                  children: '2021 год',
                },
                {
                  color: '#916ac6',
                  label: 'GTA:W',
                  children: '2022 год',
                },
                {
                  color: '#916ac6',
                  label: '???',
                  children: '2023 год',
                },
                {
                  color: '#916ac6',
                  label: '???',
                  children: '2024 год',
                },
              ]}
            />
          </Styled.TextBox>

        </Styled.TextWrapper>
      </Styled.MainBox>
    </Styled.MainWrapper>
  );
};

export default Main;
