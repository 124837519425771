import React, { useState, useEffect } from 'react';
import Styled from './styled';

const Header = () => {

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Header
      active={scrolled}
    >
      <Styled.HeaderContent>
        <a href='../'><Styled.Logo src="/assets/images/header/CTSOB.png" /></a>
        <Styled.Links active={scrolled}>
          <Styled.NavLink to={'/'}>
            About
          </Styled.NavLink>
          <Styled.NavLink to={'/members'}>
            Members
          </Styled.NavLink>
          <Styled.NavLink to={'/gallery'}>
            Gallery
          </Styled.NavLink>
        </Styled.Links>
      </Styled.HeaderContent>
    </Styled.Header>
  );
};

export default Header;
