import React, {useMemo} from 'react';
import Styled from './styled'
import {useParams} from "react-router-dom";
import {allMembers} from '../../shared/consts/membersData'
import YoutubeIcon from "./_resources/YoutubeIcon";
import TelegramIcon from "./_resources/TelegramIcon";
import VkIcon from "./_resources/VkIcon";
import { FaDiscord } from "react-icons/fa6";

const Profile = () => {

  let {name} = useParams();

  const currentMember = useMemo(() => {
    return allMembers.find(member => {
      return member.nickname === name
    })
  }, [name])

  let ImageSrc: any = useMemo(() => {
    if (currentMember?.executed) {
      return "/assets/images/members/executed.png";
    } else if (currentMember?.retired) {
      return "/assets/images/members/retired.png";
    } else {
      return currentMember?.image;
    }
  }, [currentMember?.executed, currentMember?.retired, currentMember?.image])

  const serverList = currentMember?.server?.split(', ');

  const getAgeEnding = (num: any): string => {
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return 'лет';
    }

    if (lastDigit === 1) {
      return 'год';
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
      return 'года';
    }

    return 'лет';
  };

  const calculateAge = () => {
    const currentYear = new Date().getFullYear();
    const birthYear = currentMember?.age;
    
    if (!birthYear) {
      return NaN;
    } else {
      return currentYear - birthYear;
    }
};

const age = calculateAge();

  return (
    <>
      <Styled.ProfileWrapper>
        <Styled.ProfileBox>
          <Styled.ProfileTop>
            <Styled.Avatar src={ImageSrc} draggable='false' />
            <Styled.InfoBox>
              <div>
                {currentMember?.name ? (
                  <>
                    <Styled.Text>Name: {currentMember?.name}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.age && currentMember.age !== 1000 ? (
                  <>
                    <Styled.Text>Age: {age} {getAgeEnding(currentMember.age)}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.place ? (
                  <>
                    <Styled.Text>Country: {currentMember?.place}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.nickname ? (
                  <>
                    <Styled.Text>OOC nick: {currentMember?.nickname}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.rank ? (
                  <>
                    <Styled.Text>In-game rank: {currentMember?.rank}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.experience ? (
                  <>
                    <Styled.Text>Experience: {currentMember?.experience}</Styled.Text>
                  </>
                ) : null}
                {currentMember?.server ? (
                   <>
                    <Styled.ServerText>
                      Servers: {
                      currentMember?.server ?
                        <ul>
                          {serverList?.map(server => (
                            <li key={server}>{server}</li>
                          ))}
                        </ul> : ''
                    }
                   </Styled.ServerText>
                   </>
                ) : null}
              </div>
              <div>
                {currentMember?.youtube
                  ?
                  <Styled.Text>
                    Youtube:
                    <Styled.Link href={currentMember?.youtube} target="_blank">
                      <YoutubeIcon/>
                    </Styled.Link>
                  </Styled.Text>
                  :
                  ''
                }
                {currentMember?.tg
                  ?
                  <Styled.Text>
                    Telegram:
                    <Styled.Link href={currentMember?.tg} target="_blank">
                      <TelegramIcon/>
                    </Styled.Link>
                  </Styled.Text>
                  :
                  ''
                }
                {currentMember?.vk
                  ?
                  <Styled.Text>
                    VKontakte:
                    <Styled.Link href={currentMember?.vk} target="_blank">
                      <VkIcon/>
                    </Styled.Link>
                  </Styled.Text>
                  :
                  ''
                }
                {currentMember?.ds
                  ?
                  <Styled.Text>
                    Discord:
                    <Styled.DSLink href={`https://discordapp.com/users/${currentMember?.ds}`} target='_blank' rel='noreferrer'><FaDiscord /></Styled.DSLink>
                  </Styled.Text>
                  :
                  ''
                }
              </div>
            </Styled.InfoBox>
          </Styled.ProfileTop>
          <Styled.ProfileBottom>
          {currentMember?.about && currentMember.about.length > 0 ? (
            <>
              <Styled.Text gradient>About</Styled.Text>
              <Styled.Text>
                {currentMember.about}
              </Styled.Text>
            </>
          ) : null}
          {currentMember?.aboutGta && currentMember.aboutGta.length > 0 ? (
            <>
              <Styled.Text gradient>About GTA</Styled.Text>
              <Styled.Text>
                {currentMember.aboutGta}
              </Styled.Text>
            </>
          ) : null}
          </Styled.ProfileBottom>
        </Styled.ProfileBox>
      </Styled.ProfileWrapper>
    </>
  );
};

export default Profile;
