import React, {FC, useEffect} from "react";
import {Route, Navigate, Routes as RouterRoutes} from "react-router-dom";
import Profile from "../pages/Profile";
import Main from '../pages/Main';
import Members from '../pages/Members';
import Gallery from '../pages/Gallery';
import Supervisors from '../pages/Members/Supervisors';
import Officers from '../pages/Members/Officers';
import Retired from '../pages/Members/Retired';
import Executed from '../pages/Members/Executed';
import NotFoundPage from "../pages/404";



const Routes: FC = () => (
    <RouterRoutes>
      <Route path="/" element={<Main/>} />
      <Route path="/members/:category/:name" element={<Profile/>}/>
      <Route path="/members/Supervisors" element={<Supervisors />} />
      <Route path="/members/Officers" element={<Officers />} />
      <Route path="/members/Retired" element={<Retired />} />
      <Route path="/members/Executed" element={<Executed />} />
      <Route path="/members" element={<Members/>}/>
      <Route
        path="/members/*"
        element={<Navigate
          to={'/members'}
          replace/>}
      />
      <Route path="/gallery" element={<Gallery/>} />
      <Route path="/*" element={<NotFoundPage/>} />
    </RouterRoutes>
);

export default Routes;

