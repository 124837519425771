import React from 'react';
import Styled from './styled'
import MemberList from "../../../componets/MemberList";
import { supervisors } from '../../../shared/consts/membersData';

const Members = () => {
  return (
    <Styled.Wrapper>
      <MemberList members={supervisors} title={'Supervisors'}/>
    </Styled.Wrapper>
  );
};

export default Members;
